// extracted by mini-css-extract-plugin
export var reviewCard = "reviews-module--review-card--kAtYh";
export var reviewCardBody = "reviews-module--review-card-body--4K8Vq";
export var reviewCardHeader = "reviews-module--review-card-header--PV94u";
export var reviewCardImage1 = "reviews-module--review-card-image-1--1NqZY";
export var reviewCardImage2 = "reviews-module--review-card-image-2--Ev4Ht";
export var reviewCardImage3 = "reviews-module--review-card-image-3--oWKbl";
export var reviewCardImage4 = "reviews-module--review-card-image-4--irQBL";
export var reviewCardImage5 = "reviews-module--review-card-image-5--UMrBi";
export var reviewCardImageWrapper = "reviews-module--review-card-image-wrapper--EMprz";
export var reviewCardName = "reviews-module--review-card-name--k+2Kq";
export var reviewCardNameSan = "reviews-module--review-card-name-san--Xxbcu";
export var reviewCardSns = "reviews-module--review-card-sns--foNkm";
export var reviewCardSnsLink = "reviews-module--review-card-sns-link--9il3F";
export var reviewCardSnsLogo = "reviews-module--review-card-sns-logo--iJSo2";
export var reviewCardTitle = "reviews-module--review-card-title--NenhE";
export var reviewCardTitleWrapper = "reviews-module--review-card-title-wrapper--YQO34";
export var reviewCardWrapper = "reviews-module--review-card-wrapper--10ur3";
export var reviewsContainer = "reviews-module--reviews-container--UOcFd";
export var reviewsHr = "reviews-module--reviews-hr--FWCui";
export var reviewsPagination = "reviews-module--reviews-pagination--m-hbz";
export var reviewsPaginationCurrentPage = "reviews-module--reviews-pagination-current-page--N7hk1";
export var reviewsPaginationLink = "reviews-module--reviews-pagination-link--WoYJF";
export var reviewsTitle = "reviews-module--reviews-title--zGkNH";
export var reviewsTitleJp = "reviews-module--reviews-title-jp--xN1EN";