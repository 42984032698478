import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import {
  reviewsContainer,
  reviewsTitle,
  reviewsTitleJp,
  reviewsHr,
  reviewCardWrapper,
  reviewCard,
  reviewCardHeader,
  reviewCardImageWrapper,
  reviewCardImage1,
  reviewCardImage2,
  reviewCardImage3,
  reviewCardImage4,
  reviewCardImage5,
  reviewCardTitleWrapper,
  reviewCardTitle,
  reviewCardName,
  reviewCardNameSan,
  reviewCardSns,
  reviewCardSnsLink,
  reviewCardSnsLogo,
  reviewCardBody,
  reviewsPagination,
  reviewsPaginationLink,
  reviewsPaginationCurrentPage,
} from '../styles/reviews.module.css'

const Reviews = (props) => {
  const reviews = props.data.allMdx.edges
  const { currentPage, numReviewsPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numReviewsPages
  const prevPage = currentPage - 1 === 1 ? "/reviews" : `/reviews/${(currentPage - 1).toString()}`
  const nextPage = `/reviews/${(currentPage + 1).toString()}`

  const getBlobsNumber = () => {
    const min = Math.ceil(1)
    const max = Math.floor(5)
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const getAvatarNumber = () => {
    const min = Math.ceil(1)
    const max = Math.floor(2)
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  return (
    <Layout
      isHome={false}
      title="Reviews - お客さまの声"
      description="今までにNEUTRALの「クリエイティブレッスン」や「ライフコーチング」を受講していただいた方からの感想・レビューをご紹介します。"
    >
      <div className={reviewsContainer}>
        <h1 className={reviewsTitle}>Reviews</h1>
        <h2 className={reviewsTitleJp}>お客さまの声</h2>
        <hr className={reviewsHr}/>
        <div className={reviewCardWrapper}>
          {reviews.map(({ node: review }) => (
            <div key={review.id} className={reviewCard}>
              <div className={reviewCardHeader}>
                <div className={reviewCardImageWrapper}>
                  {review.frontmatter.avatar_image
                  ?
                    <GatsbyImage
                      className={
                        getBlobsNumber() === 1
                        ? reviewCardImage1
                        : getBlobsNumber() === 2
                        ? reviewCardImage2
                        : getBlobsNumber() === 3
                        ? reviewCardImage3
                        : getBlobsNumber() === 4
                        ? reviewCardImage4
                        : reviewCardImage5
                      }
                      image={getImage(review.frontmatter.avatar_image)}
                      alt={review.frontmatter.name}
                    />
                  :
                    review.frontmatter.gender === "male"
                    ?
                      getAvatarNumber() === 1
                      ?
                        <StaticImage
                          className={
                            getBlobsNumber() === 1
                            ? reviewCardImage1
                            : getBlobsNumber() === 2
                            ? reviewCardImage2
                            : getBlobsNumber() === 3
                            ? reviewCardImage3
                            : getBlobsNumber() === 4
                            ? reviewCardImage4
                            : reviewCardImage5
                          }
                          alt="Avatar - Male"
                          src="../images/avatar-male-1.jpg"
                        />
                      :
                        <StaticImage
                          className={
                            getBlobsNumber() === 1
                            ? reviewCardImage1
                            : getBlobsNumber() === 2
                            ? reviewCardImage2
                            : getBlobsNumber() === 3
                            ? reviewCardImage3
                            : getBlobsNumber() === 4
                            ? reviewCardImage4
                            : reviewCardImage5
                          }
                          alt="Avatar - Male"
                          src="../images/avatar-male-2.jpg"
                        />
                    :
                      getAvatarNumber() === 1
                      ?
                        <StaticImage
                          className={
                            getBlobsNumber() === 1
                            ? reviewCardImage1
                            : getBlobsNumber() === 2
                            ? reviewCardImage2
                            : getBlobsNumber() === 3
                            ? reviewCardImage3
                            : getBlobsNumber() === 4
                            ? reviewCardImage4
                            : reviewCardImage5
                          }
                          alt="Avatar - Female"
                          src="../images/avatar-female-1.jpg"
                        />
                      :
                        <StaticImage
                          className={
                            getBlobsNumber() === 1
                            ? reviewCardImage1
                            : getBlobsNumber() === 2
                            ? reviewCardImage2
                            : getBlobsNumber() === 3
                            ? reviewCardImage3
                            : getBlobsNumber() === 4
                            ? reviewCardImage4
                            : reviewCardImage5
                          }
                          alt="Avatar - Female"
                          src="../images/avatar-female-2.jpg"
                        />
                  }
                </div>
                <div className={reviewCardTitleWrapper}>
                  <h2 className={reviewCardTitle}>"{review.frontmatter.title}"</h2>
                  <p className={reviewCardName}>
                    {review.frontmatter.name}
                    <span className={reviewCardNameSan}>さん</span>
                  </p>
                  {review.frontmatter.twitter_link &&
                  <p className={reviewCardSns}>
                    <a className={reviewCardSnsLink} href={review.frontmatter.twitter_link} target="_blank" rel="noopener noreferrer">
                      <StaticImage
                        className={reviewCardSnsLogo}
                        alt="Twitter Logo"
                        src="../images/icon-twitter.svg"
                      />
                      @{review.frontmatter.twitter_name}
                    </a>
                  </p>}
                  {review.frontmatter.instagram_link &&
                  <p className={reviewCardSns}>
                    <a className={reviewCardSnsLink} href={review.frontmatter.instagram_link} target="_blank" rel="noopener noreferrer">
                      <StaticImage
                        className={reviewCardSnsLogo}
                        alt="Twitter Logo"
                        src="../images/icon-instagram.svg"
                      />
                      @{review.frontmatter.instagram_name}
                    </a>
                  </p>}
                </div>
              </div>
              <div className={reviewCardBody}>
                <MDXRenderer>{review.body}</MDXRenderer>
              </div>
            </div>
          ))}
        </div>
        <div className={reviewsPagination}>
          {!isFirst && <Link className={reviewsPaginationLink} to={prevPage} rel="prev">← prev</Link>}
          {Array.from({ length: numReviewsPages }, (_, i) => (
            <Link className={reviewsPaginationLink} key={`pagination-number${i + 1}`} to={`/reviews/${i === 0 ? "" : i + 1}`}>
              <p className={i + 1 === currentPage ? reviewsPaginationCurrentPage : null}>{i + 1}</p>
            </Link>
          ))}
          {!isLast && <Link className={reviewsPaginationLink} to={nextPage} rel="next">next →</Link>}
        </div>
      </div>
    </Layout>
  )
}

export default Reviews

export const reviewsQuery = graphql`
  query ReviewsQuery($skip: Int!, $limit: Int!) {
    allMdx(
      filter: {frontmatter: {type: {eq: "review"}}}
      sort: {fields: frontmatter___number, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            name
            avatar_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            gender
            twitter_link
            twitter_name
            instagram_link
            instagram_name
          }
          body
        }
      }
    }
  }
`